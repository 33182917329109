<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-card-title class="headline">
        {{ isEditing ? "Edit" : "Add a new" }} Agreement
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="save" method="post" id="agreement-form">
          <v-text-field
            label="Title *"
            v-model="fields.title"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('title')"
            :error-messages="errors['title']"
          ></v-text-field>
          <v-file-input
            label="Document"
            v-model="fields.document"
            placeholder="Choose pdf"
            background-color="white"
            outlined
            :error="errors.hasOwnProperty('document')"
            :error-messages="errors['document']"
          ></v-file-input>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="agreement-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      agreement: {},
      errors: {},
      fields: {
        title: null,
        document: null,
      },
    };
  },

  computed: {},

  methods: {
    open(agreement = null) {
      if (agreement !== null) {
        this.agreement = agreement;
        this.isEditing = true;
        this.fields.title = agreement.title;

        this.fields.document = agreement.document
          ? new File([agreement.document], agreement.document)
          : null;
      }

      this.dialog = true;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        customerId: this.$route.params.customerId,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.agreementId = this.agreement.id;
      }

      this.$store
        .dispatch("customers/saveAgreement", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.agreement = {};
      this.fields.title = null;
      this.fields.document = null;

      this.$refs.formWrapper.scrollTop = 0;
    },
  },
};
</script>
