<template>
  <div>
    <v-row align="center">
      <v-col cols="auto" class="pr-12">
        <h3>Finance</h3>
      </v-col>
      <v-col>
        <!-- <v-row align="center" justify="end" no-gutters>
          <v-btn small depressed color="accent" class="mr-2" @click="addAgreementForm.dialog = true">
            <v-icon left>mdi-plus</v-icon>Add Agreement
          </v-btn>
        </v-row> -->
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <v-card class="mb-6">
          <v-toolbar flat dark dense :color="appColor">
            <v-toolbar-title>Agreements</v-toolbar-title>
            <v-spacer> </v-spacer>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  small
                  depressed
                  color="accent"
                  class="mr-2"
                  @click="$refs.agreementDialog.open()"
                >
                  <v-icon left>mdi-plus</v-icon>Add Agreement
                </v-btn>
              </template>
              <span>Add</span>
            </v-tooltip>
          </v-toolbar>
          <v-divider></v-divider>
          <v-data-table
            :headers="tableHeaders"
            :items="customer.agreements"
            no-data-text="No agreements found"
          >
            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-if="item.document"
                    dark
                    small
                    class="mt-2"
                    depressed
                    :href="item.asset_urls.url"
                    target="_blank"
                  >
                    <v-icon left>mdi-monitor-dashboard</v-icon>View Document
                  </v-btn>
                </template>
                <span>View</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <agreement-dialog ref="agreementDialog" />
  </div>
</template>

<script>
import AgreementDialog from "./components/AgreementDialog";

export default {
  props: {
    customer: {
      type: Object,
      required: true,
    },
    appColor: {
      type: String,
    },
  },

  components: {
    AgreementDialog,
  },

  data() {
    return {
      searchTerm: "",
      tableHeaders: [
        { text: "Title", value: "title" },
        { text: "Document", value: "document" },
        { text: "Actions", value: "actions", align: "right" },
      ],
    };
  },

  methods: {},
};
</script>
